.EditDiscountPopup {
	padding: 10px 40px;
	width: min(100%, 500px);
}

.EditDiscountPopup .body {
	text-align: center;
}

.EditDiscountPopup .error {
	text-align: center;
	color: var(--negativeAccentColor)
}

.EditDiscountPopup .subStory {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: auto;
	width: 65%;
	text-align: start;
}

.EditDiscountPopup .ProductsList .ArchivedProduct {
	text-decoration: line-through;
	color: var(--lightTextColor);
}

.EditDiscountPopup .subStory label {
	color: var(--lightTextColor);
}

.EditDiscountPopup .ActionContainer {
	padding: 8px;
	display: flex;
	justify-content: flex-end;
	font-size: 18px;
	font-weight: bold;
}

.EditDiscountPopup .AmountInput {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	gap: 8px;
	width: 100%;
	white-space: nowrap;
}

.EditDiscountPopup .AmountInput .amountInput {
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;

}

.EditDiscountPopup .DoneButtonContainer {
	padding: 40px 0;
	width: 100%;
	display: flex;
	justify-content: center;
}

.EditDiscountPopup .DoneButton {
	padding-left: 40px;
	padding-right: 40px;
}

.EditDiscountPopup .content .body {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

.EditDiscountPopup .AmountContainer {
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.EditDiscountPopup .viewButton {
	padding-top: 20px;
}
