.AddDiscountPopup {
	padding: 10px 40px;
	width: min(100%, 500px);
}

.AddDiscountPopup .body {
	text-align: center;
}

.AddDiscountPopup .error {
	text-align: center;
	color: var(--negativeAccentColor)
}

.AddDiscountPopup .subStory {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 65%;
}

.AddDiscountPopup .subStory label {
	color: var(--lightTextColor);
}

.AddDiscountPopup .ActionContainer {
	padding: 8px;
	display: flex;
	justify-content: flex-end;
	font-size: 18px;
	font-weight: bold;
}

.AddDiscountPopup .AmountInput {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	gap: 8px;
	width: 100%;
	white-space: nowrap;
}

.AddDiscountPopup .AmountInput .amountInput {
    width: -moz-available;
    width: -webkit-fill-available;
    width: stretch;

}

.AddDiscountPopup .DoneButtonContainer {
	padding: 40px 0;
	width: 100%;
	display: flex;
	justify-content: center;
}

.AddDiscountPopup .DoneButton {
	padding-left: 40px;
	padding-right: 40px;
}

.AddDiscountPopup .content .body {
	display: flex;
	flex-direction: column;
	gap: 40px;
	margin: auto;
	align-items: center;
}

.AddDiscountPopup .AmountContainer {
	display: flex;
	flex-direction: column;
	gap: 25px;
}
