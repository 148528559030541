.DiscountCard {
	width: 100%;
	padding: 24px;
	background-color: white;
	background-color: #f1f9fe;
	border: 6px solid #f1f9fe;
	display: flex;
	justify-content: center;
	flex-direction: column;
	overflow-x: hidden;
	cursor: pointer;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 100%;
}

.DiscountCard .DiscountCardTitle {
	overflow-x: hidden;
	font-size: 30px;
	width: 100%;
	text-align: center;
	padding: 0 2px;
}

.DiscountCard .ProductsList .ArchivedProduct {
	text-decoration: line-through;
}

.DiscountCard .DiscountCardValue {
	font-weight: bold;
	color: var(--lightTextColor);
}


.DiscountCard .DiscountCardValue.Inactive {
	color: var(--negativeAccentColor);
}
