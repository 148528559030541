.DiscountOrdersPopup .content {
	padding: 0px 32px;
}

.DiscountOrdersPopup .CustomersTable thead tr {
	border-bottom: 3px solid var(--borderColor);
}

.DiscountOrdersPopup .CustomersTable th {
	font-weight: 400;
	color: var(--lightTextColor);
	text-align: start;
	padding-bottom: 10px;
}

.DiscountOrdersPopup .CustomersTable tbody tr:first-child td {
	padding-top: 20px;
}

.DiscountOrdersPopup .CustomersTable td {
	text-align: start;
	padding-bottom: 20px;
}

.DiscountOrdersPopup .CustomersTable {
    table-layout: fixed;
	border-collapse: collapse;
}

.DiscountOrdersPopup .CustomerCount {
	font-size: 24px;
	padding-bottom: 32px;
}
