.AppliestoProductsList .ProductList {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.AppliestoProductsList .ProductList .CheckboxLabel {
	display: flex;
	flex-wrap: nowrap;
}

.AppliestoProductsList .ProductList .CheckboxLabel .Label {
	text-align: start;
}

.AppliestoProductsList .ProductList input[type=checkbox] {
	margin: 0;
	margin-right: 6px;
	margin-top: 6px
}
